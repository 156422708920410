import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from './SectionE.module.css';
import './pricing.css'

import {
  Button,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const SectionE = () => {
  const [isLifetime, setisLifetime] = useState(false);
  const sectionRef = useRef(null); // Reference to the section

  const handleToggle = (event) => {
    setisLifetime(event.target.checked);
  };

  const navigateTo = useNavigate();

  // const navigateToContactUs = ()=>{
  //   window.location.href('/contact-us');
  // }
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Add the animation class when the section is in the viewport
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-on-scroll-c');
        }
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1, // trigger the callback when 10% of the element is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const paymentLink = "https://buy.stripe.com/test_4gw7w7cqX1wE6IgfZ0";
  // Helper function to render a feature with an icon
  const renderFeature = (included, text) => (
    <ListItem style={{ padding: "0.1rem" }}>
      <ListItemIcon>
        {included ? (
          <CheckIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        ) : (
          <ClearIcon style={{ color: '#0262DF', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        )}
      </ListItemIcon>

      <p className={Classes.card1_p} >{text}</p>
    </ListItem>
  );
  
  // Helper function to render a feature with an icon
  const renderFeature2 = (included, text) => (
    <ListItem style={{ padding: "0.1rem" }}>
      <ListItemIcon>
        {included ? (
          <CheckIcon style={{ color: 'White', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        ) : (
          <ClearIcon style={{ color: 'red', marginLeft: "-5px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        )}
      </ListItemIcon>
      <p className={Classes.card2_p} >{text}</p>
    </ListItem>
  );
  
  const renderFeature3 = (included, text) => (
    <ListItem style={{ padding: "0.1rem" }}>
      <ListItemIcon>
        {included ? (
          <CheckIcon style={{ color: '#0262DF', marginLeft: "-2px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        ) : (
          <ClearIcon style={{ color: '#0262DF', marginLeft: "-2px", lineHeight: width > 1300 ? "0rem" : "1rem", height: '0.8em', width: '0.8em' }} />
        )}
      </ListItemIcon>

      <p className={Classes.card1_p} >{text}</p>
    </ListItem>
  );
  
  const navigateToLogin = () => {
    navigateTo('/login');
  }
  const { user } = useSelector((state) => state.auth);
  const handleCheckout =  async (planName) => {
    console.log({
      planName,
      user
    })
    if(user === null){
      navigateTo('/login');
    }else{
      //mange payment operation
      if(planName === 'free'){
        //checkout for free account

        //checkout for monthly account
        const newRD = 3;
        const email = user.email;



        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await axios.post(`https://pitchperfecter-0bcf30b2e117.herokuapp.com/create-checkout-session`, {
          subscription: "free",
          remainingDecks: newRD,
          email,
          userId: user._id,
        })

        const url = response.data.url;
        window.location.href = url;

      }else if(planName === 'monthly'){
        //checkout for monthly account
        const newRD = 15;
        const email = user.email;



        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await axios.post(`https://pitchperfecter-0bcf30b2e117.herokuapp.com/create-checkout-session`, {
          subscription: "monthly",
          remainingDecks: newRD,
          email,
          userId: user._id,
        })

        const url = response.data.url;
        window.location.href = url;

      } else if(planName === 'yearly'){
        //checkout for yearly account

        const newRD = 180;
        const email = user.email;



        // const response = await backend.put(`/api/user/${user._id}`, body);
        const response = await axios.post(`https://pitchperfecter-0bcf30b2e117.herokuapp.com/create-checkout-session`, {
          subscription: "lifetime",
            remainingDecks: newRD,
            email,
            userId: user._id,
            type: "lifetime"
        })

        const url = response.data.url;
        window.location.href = url;

      }
    }
  }

  return (
    <section id='pricing' ref={sectionRef} className={Classes.section}>
      <div className={Classes.flexContainer}>
        <div className={Classes.flexItem}>
          <AnimateTopToBottom delay={0.3}>
            <Button variant="contained" className="howItWorksBtn" > Pricing </Button>
          </AnimateTopToBottom>
          <AnimateTopToBottom delay={0.4}>
            <h1 className={Classes.heading}> Powerful yet affordable </h1>
          </AnimateTopToBottom>
          <AnimateTopToBottom delay={0.5}>
            <div className={Classes.pricingBtns} >
              <button onClick={() => setisLifetime(false)} className={!isLifetime ? Classes.activeButton : Classes.inactiveButton}>
                Monthly
              </button>
              <button onClick={() => setisLifetime(true)} className={isLifetime ? Classes.activeButton : Classes.inactiveButton}>
                Lifetime
              </button>
              {/* <img src='save15.png' alt='save-15' className={Classes.saveImage} /> */}
            </div>
          </AnimateTopToBottom>
        </div>
      </div>
      <AnimateTopToBottom delay={0.6}>
        <div className={Classes.gridContainer}>
          {/* Free Plan */}
          <div className={Classes.card}>
            <h2 className={Classes.planName}>Free</h2>
            <h1 className={Classes.price} style={{ marginTop: '5px' }} >$0<span className={Classes.priceSuffix}>per month</span></h1>
            <ul className={Classes.featureList}>
              {renderFeature(true, "3 uploads per month")}
              {renderFeature(true, "Slide-by-slide analysis")}
              {renderFeature(true, "Comprehensive summary")}
              {renderFeature(false, "Premium templates")}
              {renderFeature(false, "Business chatbot")}
            </ul>
            <button onClick={() => handleCheckout('free')} variant="contained" className={`${Classes.buttonStyle}`}><h5 className="m0 fw700" >Choose this plan</h5></button>
          </div>
          {/* Premium Plan */}
          <div className={`${Classes.card} ${Classes.bgBlue}`}>
            <h2 className={`${Classes.planName} ${Classes.colorWhite}`} >Premium</h2>
            <h1 className={`${Classes.price} ${Classes.colorWhite}`} style={{ marginTop: '5px', borderBottom: '1px solid white' }} >{isLifetime ? "$49.99" : "$9.99"}<span className={Classes.priceSuffix}>{isLifetime ? " lifetime" : " per month"}</span></h1>
            <ul className={Classes.featureList}>
              {renderFeature2(true, "15 uploads per month")}
              {renderFeature2(true, "Slide-by-slide analysis")}
              {renderFeature2(true, "Comprehensive summary")}
              {renderFeature2(true, "Premium templates")}
              {renderFeature2(true, "Business chatbot")}
            </ul>
            <button onClick={() => handleCheckout(`${isLifetime?'yearly':'monthly'}`)} variant="contained" className={`${Classes.buttonStyle} ${Classes.buttonBlue}`}><h5 className="m0 fw700" >Choose this plan</h5></button>
          </div>
          {/* Enterprise Plan */}
          <div className={Classes.card}>
            <h2 className={Classes.planName}>Enterprise</h2>
            <h1 className={Classes.price} style={{ marginTop: '5px' }} > Custom </h1>
            <ul className={Classes.featureList}>
              <ListItem style={{ padding: "0.1rem" }}>
                <p className={`${Classes.card1_p} ${Classes.margin45} `} > White-label solutions for: </p>
              </ListItem>
              {renderFeature3(true, "Accelerators")}
              {renderFeature3(true, "Incubators")}
              {renderFeature3(true, "Universities")}
              {renderFeature3(true, "Competitions")}
            </ul>
            <a href='/contact-us' >
              <button variant="contained" className={`${Classes.buttonStyle}`}> <h5 className="m0 fw700" >Contact us</h5> </button>
            </a>
          </div>
        </div>
      </AnimateTopToBottom>
    </section >
  );
}

export default SectionE;
